var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dropdown" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-sm btn-secondary dropdown-toggle",
        attrs: {
          id: "dropdownMenuButton",
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
          "data-boundary": "viewport",
          disabled: _vm.disabled,
        },
        on: {
          click: function ($event) {
            _vm.showDropDown = !_vm.showDropDown
          },
        },
      },
      [
        _c("span", { class: `colorSwatch ${_vm.colorSelected || ""}` }, [
          _vm._v(_vm._s(" ")),
        ]),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "dropdown-menu",
        class: _vm.showDropDown ? "show" : "",
        attrs: { "aria-labelledby": "dropdownMenuButton" },
      },
      _vm._l(_vm.colorOptions, function (color) {
        return _c(
          "span",
          {
            key: `${color}${_vm.itemIndex}`,
            staticClass: "dropdown-item",
            on: {
              click: function ($event) {
                _vm.colorChange(color, _vm.itemIndex)
                _vm.showDropDown = false
              },
            },
          },
          [
            _c("span", { class: `colorSwatch ${color}` }),
            _c("span", [_vm._v(_vm._s(color))]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }