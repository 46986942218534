<template>
<div class="dropdown">
    <button
        id="dropdownMenuButton"
        class="btn btn-sm btn-secondary dropdown-toggle"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-boundary="viewport"
        :disabled="disabled"
        @click="showDropDown = !showDropDown"
    >
        <span>
            <template v-if="initialEmoji">{{ initialEmoji }}</template>
            <i
                v-else
                class="la la-smile-o"
            />
        </span>
    </button>
    <div
        class="dropdown-menu"
        :class="showDropDown ? 'show' : ''"
        aria-labelledby="dropdownMenuButton"
    >
        <Picker
            :set="'apple'"
            :data="emojiIndex"
            :show-preview="false"
            :default-skin="4"
            :title="''"
            :disabled="disabled"
            @select="chooseEmoji"
        />
    </div>
</div>
</template>

<script>
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast';
import 'emoji-mart-vue-fast/css/emoji-mart.css';
import data from 'emoji-mart-vue-fast/data/apple.json';

const emojiIndex = new EmojiIndex(data, {
    exclude: ['recent'],
    emojisToShowFilter: function emojisToShowFilter(emoji) {
        const hidden = ['Reversed Hand with Middle Finger Extended', 'Pile of Poo', 'Aubergine', 'Peach'];
        const found = hidden.find((e) => e == emoji.name) || null;
        return !found;
    },
});

export default {
    name: 'EmojiPicker',
    components: {
        Picker,
    },
    props: {
        initialEmoji: {
            type: String,
            default: null,
            required: false,
        },
        onSelected: {
            type: Function,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data() {
        return {
            showDropDown: false,
            emojiIndex,
        };
    },
    methods: {
        chooseEmoji(emoji) {
            this.showDropDown = false;
            this.onSelected(emoji);
        },
    },
};
</script>

<style scoped>
.dropdown-menu.show {
  width: 340px;
  display: flex;
  flex-wrap: wrap;
}

</style>
