var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dropdown" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-sm btn-secondary dropdown-toggle",
        attrs: {
          id: "dropdownMenuButton",
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
          "data-boundary": "viewport",
          disabled: _vm.disabled,
        },
        on: {
          click: function ($event) {
            _vm.showDropDown = !_vm.showDropDown
          },
        },
      },
      [
        _c(
          "span",
          [
            _vm.initialEmoji
              ? [_vm._v(_vm._s(_vm.initialEmoji))]
              : _c("i", { staticClass: "la la-smile-o" }),
          ],
          2
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "dropdown-menu",
        class: _vm.showDropDown ? "show" : "",
        attrs: { "aria-labelledby": "dropdownMenuButton" },
      },
      [
        _c("Picker", {
          attrs: {
            set: "apple",
            data: _vm.emojiIndex,
            "show-preview": false,
            "default-skin": 4,
            title: "",
            disabled: _vm.disabled,
          },
          on: { select: _vm.chooseEmoji },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }