<template>
<div class="dropdown">
    <button
        id="dropdownMenuButton"
        class="btn btn-sm btn-secondary dropdown-toggle"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-boundary="viewport"
        :disabled="disabled"
        @click="showDropDown = !showDropDown"
    >
        <span :class="`colorSwatch ${colorSelected || ''}`">{{ " " }}</span>
    </button>
    <div
        class="dropdown-menu"
        :class="showDropDown ? 'show' : ''"
        aria-labelledby="dropdownMenuButton"
    >
        <span
            v-for="color in colorOptions"
            :key="`${color}${itemIndex}`"
            class="dropdown-item"
            @click="colorChange(color, itemIndex); showDropDown = false;"
        >
            <span :class="`colorSwatch ${color}`" />
            <span>{{ color }}</span>
        </span>
    </div>
</div>
</template>

<script>
export default {
  name: 'ColorSwatch',
  components: {},
  props: {
    colorChange: {
      type: Function,
      default: null,
    },
    colorSelected: {
      type: String,
      required: true,
    },
    itemIndex: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showDropDown: false,
      colorOptions: [
        'Red',
        'Orange',
        'Yellow',
        'Green',
        'Blue',
        'Purple',
        'White',
        'Brown',
      ],
    };
  },
};
</script>

<style scoped>

.dropdown-menu.show {
  width: 17rem;
  display: flex;
  flex-wrap: wrap;
}

.dropdown-item {
  min-width: 7rem;
  width: 7rem;
  cursor: pointer;
}

</style>
